import React from "react";
import styled, { keyframes } from "styled-components";
import btnArrow from "../../images/stararrow.svg";
import Theme from "../theme/Theme";
import { Link } from "react-router-dom";

const URL = process.env.WhatsAppUrl;
console.log(URL);
const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RoundedButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  max-width: 20.625rem;
  height: 3.5rem;
  font-weight: 500;
  font-family: "Trap-Bold", sans-serif;
  /* border-radius: 30px; */
  border-radius: 1rem;
  background: ${Theme.colors.primaryPink};
  color: ${Theme.colors.white};
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 2.5rem;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: ${Theme.colors.hoverColor};
    .rotate {
      animation: ${rotate360} 2s infinite linear;
    }
  }

  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin-top: 2rem;
    height: 2.5rem;
    padding: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
    margin-top: 2rem;
    height: 2.5rem;
    padding: 1.5rem;
  }
`;

const Icon = styled.img`
  margin-left: 0.5rem;
  width: 2rem;
  height: 2rem;
  @media (max-width: 768px) {
    width: 2rem;
    height: 2rem;
  }

  @media (max-width: 480px) {
    width: 1.625rem;
    height: 1.625rem;
  }
`;

function RoundedButtonWithIcon({ text, onClick }) {
  return (
    <>
      {/* <Link
        to="https://web.whatsapp.com/"
        target="_blank"
        style={{ textDecoration: "none" }}
      > */}
      <RoundedButton onClick={onClick}>
        {text}
        <Icon
          src={btnArrow}
          alt="Button Icon"
          className="rotate"
          width="2rem"
          height="2rem"
        />
      </RoundedButton>
      {/* </Link> */}
    </>
  );
}

export default RoundedButtonWithIcon;
